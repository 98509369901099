var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "join-row-wrap"
  }, [_c('div', {
    staticClass: "join-row join-row--form"
  }, [_vm._m(0), _c('div', {
    staticClass: "join-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("아이디(이메일)")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-email"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email1,
      expression: "user.email1"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "email1"
    },
    domProps: {
      "value": _vm.user.email1
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "email1", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("@")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email2,
      expression: "user.email2"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "email2"
    },
    domProps: {
      "value": _vm.user.email2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "email2", $event.target.value);
      }
    }
  }), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email3,
      expression: "user.email3"
    }],
    staticClass: "select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user, "email3", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        _vm.user.email2 = _vm.user.email3;
      }]
    }
  }, [_c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v("직접입력")]), _c('option', {
    domProps: {
      "value": `naver.com`
    }
  }, [_vm._v("naver.com")]), _c('option', {
    domProps: {
      "value": `daum.net`
    }
  }, [_vm._v("daum.net")]), _c('option', {
    domProps: {
      "value": `google.com`
    }
  }, [_vm._v("google.com")])])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("비밀번호")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.password,
      expression: "user.password"
    }],
    staticClass: "input",
    attrs: {
      "type": "password",
      "name": "password"
    },
    domProps: {
      "value": _vm.user.password
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "password", $event.target.value);
      }, _vm.input]
    }
  }), _c('small', {
    staticClass: "text-grey text-grey--right"
  }, [_vm._v("(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("비밀번호 확인")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.passwordConfirm,
      expression: "user.passwordConfirm"
    }],
    staticClass: "input",
    attrs: {
      "type": "password",
      "name": "password2"
    },
    domProps: {
      "value": _vm.user.passwordConfirm
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "passwordConfirm", $event.target.value);
      }, _vm.input]
    }
  })])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_vm._m(1), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.name,
      expression: "user.name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "name"
    },
    domProps: {
      "value": _vm.user.name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "name", $event.target.value);
      }, _vm.input]
    }
  })])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("연락처")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-tel"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone1,
      expression: "user.phone1"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "name": "phone1",
      "maxlength": "4",
      "readonly": ""
    },
    domProps: {
      "value": _vm.user.phone1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "phone1", $event.target.value);
      }, _vm.input],
      "click": _vm.certify
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone2,
      expression: "user.phone2"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "name": "phone2",
      "maxlength": "4",
      "readonly": ""
    },
    domProps: {
      "value": _vm.user.phone2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "phone2", $event.target.value);
      }, _vm.input],
      "click": _vm.certify
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone3,
      expression: "user.phone3"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "name": "phone3",
      "maxlength": "4",
      "readonly": ""
    },
    domProps: {
      "value": _vm.user.phone3
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "phone3", $event.target.value);
      }, _vm.input],
      "click": _vm.certify
    }
  })])])])])])])]), _c('div', {
    staticClass: "bottom-button"
  }, [_c('button', {
    staticClass: "button button--border-primary",
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('span', [_vm._v("취소하기")])]), _c('button', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('span', [_vm._v("회원가입")])])]), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "change": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.user.postcode = postcode;
        _vm.user.address1 = address;
      }
    }
  }), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-caption join-caption--center"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("회원정보 필수입력")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_c('span', [_vm._v("이름")])]);

}]

export { render, staticRenderFns }